<template>
	<IndexSlidersTituloContainer :titulo="titulo" :link-ver-mas="linkVerMas" />
	<div class="contenedor" @click="isPaused=true">

		<button @click="previousPage">
			<ClientOnly>				
				<svg
					height="30px"
					width="30px"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 185.343 185.343"
					xml:space="preserve"
					fill="white"
					stroke="#000000"
					transform="rotate(180)"
				>
					<g id="SVGRepo_bgCarrier" stroke-width="0" />

					<g
						id="SVGRepo_tracerCarrier"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>

					<g id="SVGRepo_iconCarrier">
						<g>
							<g>
								<path
									style="fill: black"
									d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
								/>
							</g>
						</g>
					</g>
				</svg>
			</ClientOnly>
		</button>
		<div
			ref="slidercontenedor"
			class="carousel"
			tag="div"
		>
			<template 
				v-for="prod,index in productos"
				:key="prod"
			>
				<CardProd :class="{ 'hidden lg:block': index>3 }" :producto="prod" modo-vista="tipo3" />
			</template>
		</div>

		<button @click="nextPage">
			<ClientOnly>
				<svg
					height="30px"
					width="30px"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					stroke="#000000"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 185.343 185.343"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								style="fill: black"
								d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
							l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
							c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
							/>
						</g>
					</g>
				</svg>
			</ClientOnly>
		</button>
		<img
			v-if="imgSlider"
			class="my-0 hidden lg:inline-flex"
			:src="imgSlider"
			style="width:269px; height: 100%; object-fit: cover;"
		>
	</div>
			
</template>
<script lang="ts" setup>
	import type { ProductSlider } from "../../../types/products";
	import { useSlider } from '@/composables/components/useSlider';

	defineProps<{
		titulo: string;
		linkVerMas: string;
		productos: ProductSlider[];
		imgSlider?: string;
	}>();

	const { slidercontenedor, nextPage, previousPage, startAutoScroll, isPaused } = useSlider();

	onMounted(() => {
		if(window.screen.width < 768){
			isPaused.value = true;
		}
		startAutoScroll()
	});
</script>
<style scoped>
button {
	display: none;
}
.contenedor {
	display: flex;
	align-items: center;
}
.carousel {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 110px;
	padding-top: 8px;
	margin: 8px auto;
	border-radius: 8px;
    overflow-y: visible;
    overflow-x: auto;
}

.carousel > *{
	border-bottom: 0.1px solid rgb(222, 216, 216) !important;
}
.slide-move {
	transition: transform 1s;
}
.slider-title {
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
}
@media (min-width: 1024px) {	
	button {
		display: block;
	}
	.contenedor {
		display: flex;
		align-items: center;
		grid-template-rows: unset;
		height: 391px;
		padding: 0;
		background-color: #f4f4f4fe;
	}
	.carousel {
		display: flex;
		align-items: center;
		scrollbar-width: none;
		-ms-overflow-style: none;
		column-gap: 8px;
	}
	.slider-title {
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}
</style>
